@import '../../variables.module.scss';

.inputFieldContainer {
    width: calc(100% - $body-margin - $body-margin);
    margin: $body-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inputField {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;
}

.inputField input {
    width: 100%;
    height: 35px;
    border: none;
    outline: none;
}

.inputField img {
    width: min(5vw, 25px) !important;
    margin-right: $small-margin;
}
