@import '../../variables.module.scss';

.button {
    width: min(50vw, 250px);
    margin: $body-margin;
    height: 55px;
    background-color: $blue;
    border: 1px solid $blue;
    color: white;
    font-weight: bold;
    font-size: min(5vw, 20px);
    border-radius: 10px;
    cursor: pointer;
}

.button:hover {
    background-color: white;
    color: $blue !important;
    transition: background-color 0.5s ease;
}

.buttonLoader {
    margin: auto;
    border: 0.5vw solid $white;
    border-radius: 50%;
    border-top: 0.5vw solid $blue;
    width: min(10vw, 35px);
    height: min(10vw, 35px);
    overflow: hidden;

    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.responseMessage {
    font-size: $response-message-text;
}
