.container {
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container input {
    text-align: center;
}

.container select {
    box-shadow: none;
    width: min(50vw, 150px);
    height: min(10vw, 40px);
}

.container textarea {
    width: min(80vw, 700px);
    height: min(55vw, 300px);
    padding: min(5vw, 20px);
    resize: none;
    margin-bottom: min(30vw, 80px);
}

.tableController {
    width: min(80vw, 600px);
    display: flex;
    justify-content: space-between;
    margin-bottom: min(10vw, 50px);
}

.tableControllerButtons {
    width: min(30vw, 200px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: min(2vw, 10px);
    border: 1px solid gray;
}

.tableControllerButtons h4 {
    margin: 0;
}

.tableControllerButtons img {
    cursor: pointer;
    width: min(4vw, 20px);
    height: min(4vw, 20px);
}

.quoteLineItems {
    width: 98%;
    margin: auto;
    margin-top: min(8vw, 60px);
    margin-bottom: min(30vw, 80px);
}

.quoteLineItems table {
    margin: auto;
    table-layout: fixed;
    border-collapse: collapse;
}

.quoteLineItems table,
td,
th {
    border: 1px solid;
}

.quoteLineItems input {
    width: min(20vw, 150px);
    border: none;
    outline: none;
}

.container button {
    width: min(50vw, 300px);
    height: min(6vh, 60px);
    background-color: #47a5ed;
    border: 1px solid #47a5ed;
    color: white;
    font-weight: bold;
    font-size: min(4vw, 20px);
    border-radius: 10px;
    cursor: pointer;
    align-self: center;
    margin-top: min(15vw, 80px);
    margin-bottom: min(15vw, 80px);
}

.container button:hover {
    background-color: white;
    color: #47a5ed !important;
    transition: background-color 0.5s ease;
}

.priceEmailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: min(80vw, 150px);
}
