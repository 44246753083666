@import '../../../variables.module.scss';

.container {
    width: 80vw;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.title {
    text-align: center;
    margin-bottom: $body-margin;
}

.messageArea {
    width: calc(100% - $body-margin - $body-margin);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.messageArea textarea {
    box-shadow: none;
    border: none;
    resize: none;
    outline: none;
    background-color: $white;
    height: 300px;
    padding: min(2vw, 10px);
    margin-top: 15px;
}
