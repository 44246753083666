.header {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: min(8vw, 30px);
    padding-bottom: min(10vw, 50px);
    background-color: #f5f5f7;
}

.header img {
    width: min(30vw, 200px);
}

.middleContainer {
    width: 98vw;
    margin: auto;
}

.middleContainer h4 {
    text-align: center;
    color: gray;
}

.showCase {
    width: 90%;
    margin: auto;
    padding: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.showCase img {
    width: min(80vw, 400px);
    margin: 1vw;
    border:
        1px solid 242,
        242,
        242;
    border-radius: min(1vw, 15px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(242, 242, 242, 0.3);
    backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
    color: rgb(99, 111, 127);
}

.showCase h3 {
    text-align: center;
}

.priceList {
    width: min(95vw, 800px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    margin-top: min(1vw, 50px);
}

.priceList h2 {
    text-align: center;
    color: #47a5ed;
}

.priceList p {
    font-size: min(5vw, 16px);
}

.discountBox {
    width: min(50vw, 170px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: min(5vw, 30px);
    padding: min(1vw, 10px);
    flex-wrap: wrap;

    border:
        1px solid 242,
        242,
        242;
    border-radius: min(1vw, 15px);
    background-color: rgba(242, 242, 242, 0.3);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
    color: rgb(99, 111, 127);
}

.discountBoxInner {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.discountLine {
    position: absolute;
    left: 43px;
    top: 15px;
    width: 50px;
    height: 2px;
    background-color: red;
    transform: rotate(130deg);
    transform-origin: left center;
    opacity: 0.7;
}

.container {
    border: 2px solid #a5ce8a;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
