@import '../../variables.module.scss';

.footer {
    margin: auto;
    margin-top: $header-margin-top;
    display: flex;
    flex-direction: column;
}

.footer a,
p {
    margin: 0;
    text-decoration: none;
    color: inherit;
}

.footerInner {
    border-bottom: 1px solid $dark-gray;
    margin-bottom: min(10vw, 50px);
}

.footerInner p {
    font-size: $footer-p-tag;
}

.insuranceWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: min(10vw, 50px);
    text-align: center;
}

.insuranceWrapper img {
    width: min(70vw, 400px);
}

.middleLayer {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    align-items: center;
}

.middleLayer img {
    width: 70vw;
    max-width: 400px;
    height: auto;
}

@media only screen and (max-width: 1100px) {
    .middleLayer {
        grid-template-columns: 1fr;
        row-gap: 10%;
    }
}

.contactSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.socialMedia {
    width: min(30vw, 150px);
    display: flex;
    justify-content: space-between;
    margin-top: min(5vw, 30px);
    margin-bottom: min(5vw, 30px);
}

.socialMedia img {
    width: 100%;
}

.socialMedia a {
    width: min(8vw, 40px);
    height: min(8vw, 40px);
}

.acceptedPayments {
    width: min(55vw, 300px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: min(10vw, 100px);
    text-decoration: none;
}

.acceptedPaymentsIcons {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.acceptedPaymentsIcons img {
    width: min(7vw, 40px);
}

.mapWrapper {
    width: min(80vw, 500px);
    height: 40vh;
    overflow: hidden;
    justify-self: center;
}

.iframe {
    position: relative;
    width: 100%;
    height: 50vh;
    top: -10vh;
    border: none;
}

.footerContainer {
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: min(25vw, 80px);
    margin-bottom: min(25vw, 80px);
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $dark-gray;
}

.footerContainer a {
    font-size: $footer-p-tag;
}

.copyright p {
    font-size: $footer-p-tag;
}

@media only screen and (max-width: 1200px) {
    .footerContainer {
        flex-direction: column;
    }

    .copyright {
        text-align: center;
    }

    .subLinks {
        text-align: center;
    }
}
