@import '../../../variables.module.scss';

.placeholder {
    margin-top: $header-margin-top;
}

.dashboardWrapper {
    overflow: hidden;
    overflow-x: scroll;
}

.dashboardWrapper::-webkit-scrollbar {
    display: none;
}

.chartContainer {
    min-width: 800px;
    margin: auto;
    height: min-content;
    margin-bottom: $header-margin-top;
    overflow: hidden;
    padding: $small-padding;
    border: 1px solid $blue;
}

.pieChartWrapper {
    min-width: 830px;
    margin: auto;
    height: min-content;
    margin-bottom: $header-margin-top;
    border: 1px solid $blue;
    display: flex;
    justify-content: space-between;
}

.chartWrapper {
    width: min(100vw, 700px);
    height: 400px;
}

.buttonWrapper {
    text-align: center;
}