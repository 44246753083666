@import '../../../variables.module.scss';

.paymentForm {
    width: 94vw;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 3vw;
    margin-bottom: 3vw;
}

.paymentForm h4 {
    margin: 0;
}

.paymentFormRow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: min(5vw, 30px);
    padding: 1vw 1vw 1vw 0;
}

.paymentFormRow p {
    font-size: $response-message-text;
}

.paymentFormRowDesc {
    width: min(45vw, 250px);
}

.paymentFormMonthYear {
    width: min(30vw, 350px);
    justify-content: space-between;
    display: flex;
}

.paymentFormError {
    z-index: 1;
    top: min(14vw, 70px);
    font-size: min(2vw, 12px);
    position: absolute;
    color: red;
}

.paymentFormErrorBorder {
    border: 1px solid red !important;
}

.paymentForm input {
    width: min(10vw, 150px);
    height: 40px;
    background: #fbfbfd;
    border: 1px solid #f0eff2;
    text-align: center;
}

.paymentForm button {
    width: min(50vw, 300px);
    height: min(6vh, 60px);
    background-color: #47a5ed;
    border: 1px solid #47a5ed;
    color: white;
    font-weight: bold;
    font-size: min(4vw, 20px);
    border-radius: 10px;
    cursor: pointer;
    align-self: center;
    margin-top: 3vw;
    margin-bottom: 3vw;
}

.paymentForm button:hover {
    background-color: white;
    color: #47a5ed !important;
    transition: background-color 0.5s ease;
}

.paymentFormCard {
    margin-top: min(10vw, 50px);
    margin-bottom: min(5vw, 30px);
}

.paymentFormCardInput {
    width: min(35vw, 250px);
    display: flex;
    align-items: center;
}

.paymentFormCardInputWidth {
    width: 100%;
}

.paymentFormCardInput p {
    font-size: large;
    position: absolute;
    z-index: 1;
    margin-left: 1vw;
}

.paymentFormCardInput img {
    position: absolute;
    z-index: 1;
    width: min(9vw, 40px);
    margin-left: min(3vw, 20px);
}

.paymentFormCardInput input {
    width: 100%;
    position: relative;
    height: 40px;
    border: 1px solid #f0eff2;
    text-align: left;
    padding-left: min(15vw, 75px);
}

.paymentSuccess {
    width: 90%;
    height: 50vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.paymentSuccess img {
    width: min(10vw, 50px);
}

.centerAlign {
    text-align: center;
}
