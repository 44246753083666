@import '../../../variables.module.scss';

.sectionContainer {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: $body-margin;
}

.aboutImages {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: $body-margin;
    text-align: center;
    padding: $small-padding;
    border: 1px solid $light-gray;
}

.aboutImages img {
    width: min(60vw, 350px);
}

.serviceWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: $body-margin;
}

.serviceWrapper img {
    width: $service-section-logo-width;
}

.serviceContainer {
    width: 90%;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding: $small-padding;
}

.serviceContainer::-webkit-scrollbar {
    width: 0;
    height: 8px;
}

.serviceContainer:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.serviceContainer:hover::-webkit-scrollbar {
    width: auto;
    height: 8px;
}

.serviceContainer:hover::-webkit-scrollbar-thumb {
    background-color: $light-gray;
}

.serviceContainer a {
    margin: 0;
    text-decoration: none;
    color: inherit;
}

.clientCarousel {
    width: 90vw;
    display: flex;
    overflow: hidden;
}

.carouselContainer {
    animation: moveLeftToRight 30s linear infinite;
    white-space: nowrap;
    width: max-content;
}

.carouselItem {
    margin-right: $service-card-margin;
}

.clientCarousel img {
    width: $header-logo-width;
}

@keyframes moveLeftToRight {
    0% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.projects {
    margin-top: $body-margin;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.projects img {
    width: $project-image-width;
    margin-bottom: $service-card-margin;
}

.carWashCard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: $service-card-width;
    height: $service-card-height;
    margin-right: $stories-right-margin;
    margin-left: $service-card-margin;
    border: 2px solid $green;
    border-radius: 5px;
    cursor: pointer;
}

.carWashCard img {
    width: min(30vw, 150px);
}