@import '../../../../variables.module.scss';

.container {
    margin-top: min(20vw, 100px);
    text-align: center;
}

.formContainer {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: min(10vw, 70px);
}

.formContainer input[type='text'] {
    width: min(60vw, 280px);
    height: min(12vw, 30px);
    font-size: $commercial-font;
}

/* Table */

.tableWrapper {
    width: 100%;
    margin: auto;
    margin-top: min(10vw, 70px);
    overflow: hidden;
    overflow-x: scroll;
}

.tableWrapper::-webkit-scrollbar {
    display: none;
}

.tableWrapper table {
    width: 100%;
    margin: auto;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
}

@media only screen and (max-width: 1200px) {
    .tableWrapper table {
        width: 800px;
    }
}

.tableWrapper th {
    text-align: left;
    border: none;
    border-bottom: 1px solid gray;
}

.tableWrapper td {
    padding-top: min(5vw, 30px);
    padding-bottom: min(5vw, 30px);
    border: none;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    overflow: hidden;
}

.tableWrapper tr {
    margin-bottom: 100px !important; /* Adjust margin as needed */
}

.tableWrapper tr:hover {
    background-color: #efefef;
}

.totalDisplayContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.formButton {
    width: 90%;
    margin: auto;
    margin-top: min(10vw, 70px);
    margin-bottom: min(10vw, 70px);
    display: flex;
    justify-content: space-around;
}

.formButton button {
    width: min(55vw, 300px);
    height: min(10vw, 50px);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 1vh;
    border: 2px solid white;
}
