@import '../../variables.module.scss';

.loader {
    margin: auto;
    margin-top: min(30vw, 200px);
    margin-bottom: min(30vw, 200px);
    border: 0.5vw solid $light-gray;
    border-radius: 50%;
    border-top: 0.5vw solid $blue;
    width: min(15vw, 150px);
    height: min(15vw, 150px);
    overflow: hidden;

    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
