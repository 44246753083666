@import '../../variables.module.scss';

.storyContainer {
    margin-top: $body-margin;
    padding: min(5vw, 50px);
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;
    border-radius: 5px;
}

.storyContainer::-webkit-scrollbar {
    width: 0;
    height: 8px;
}

.storyContainer:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.storyContainer:hover::-webkit-scrollbar {
    width: auto;
    height: 8px;
}

.storyContainer:hover::-webkit-scrollbar-thumb {
    background-color: $light-gray;
}

.storyCard {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    min-width: 250px;
    height: min(70vw, 400px);
    margin-right: $stories-right-margin;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}

.storyCard:last-child {
    margin-right: 0;
}

.storyCard img {
    width: 100%;
    height: 100%;
}

.coverLogo {
    position: absolute;
    width: min(15vw, 100px) !important;
    height: min(15vw, 100px) !important;
    overflow: hidden;
    background: linear-gradient(to right, #feda75, #fa7e1e, #d62976, #962fbf);
    border-radius: 50%;
    padding: min(1vw, 5px);
}

.coverLogo img {
    background: whitesmoke;
    border-radius: 50%;
}

@media only screen and (max-width: 700px) {
    .storyCard {
        min-width: 40vw;
    }
}
