// Margin

$header-margin-top: min(15vw, 90px);
$body-margin: min(3vw, 15px);
$navigation-item-margin: min(5vw, 20px);
$service-card-margin: min(10vw, 70px);
$large-margin: min(15vw, 50px);
$small-margin: min(1vw, 10px);
$stories-right-margin: min(5vw, 50px);

// Padding

$small-padding: min(5vw, 15px);

// Width

$icon-width: min(10vw, 50px);
$sidebar-logo-width: min(18vw, 90px);
$header-logo-width: min(30vw, 150px);
$about-logo-width: min(45vw, 300px);
$service-card-width: min(35vw, 180px);
$service-section-logo-width: min(40vw, 250px);
$project-image-width: min(80vw, 500px);
$input-image-width: min(80vw, 300px);

// Height

$nav-bar-height: min(10vw, 50px);
$service-card-height: min(50vw, 250px);
$input-field-height: min(15vw, 50px);

// Color

$dark-gray: #61677a;
$light-gray: #dadada;
$white: #f6f6f6;
$green: #a5ce8a;
$blue: #47a5ed;
$orange: #e79124;

// Font

$footer-p-tag: min(2.5vw, 13px);
$commercial-font: min(5vw, 20px);

// Button response message

$response-message-text: min(3vw, 16px);
