@import '../../../variables.module.scss';

.textContainer {
    max-width: 1000px;
    margin: auto;
}

.centerAlign {
    text-align: center;
}
