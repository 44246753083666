@import '../../variables.module.scss';

.navigationBar {
    position: fixed;
    top: 0;
    height: $nav-bar-height;
    padding-top: $body-margin;
    width: calc(100% - $body-margin - $body-margin);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    background-color: white;
    z-index: 2;
}

.navigationBar img {
    width: $icon-width;
}

.backDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark-gray;
    opacity: 0.5;
    z-index: 3;
}

.sideBar {
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    width: min(60vw, 300px);
    height: 100vh;
    z-index: 3;
    padding: $body-margin;
}

.sideBarTop {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    cursor: pointer;
}

.sideBarTop img {
    width: $sidebar-logo-width;
}

.sideBarMiddle {
    display: flex;
    flex-direction: column;
}

.sideBarMiddle h4 {
    width: calc(100% - $body-margin);
    margin: 0;
    color: $dark-gray;
    padding: $navigation-item-margin 0 $navigation-item-margin $body-margin;
    border-bottom: 1px solid $light-gray;
    cursor: pointer;
}

.sideBarMiddle h4:hover {
    background-color: $light-gray;
    transition: background-color 0.5s ease;
}

.sideBarFooter {
    position: absolute;
    bottom: $large-margin;
}

@media only screen and (max-width: 700px) {
    .sideBarFooter {
        display: none;
    }
}

.sideBarFooterItem a {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark-gray;
}

.sideBarFooterItem img {
    width: $icon-width;
    margin-right: $body-margin;
}
