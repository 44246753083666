@import '../../../variables.module.scss';

.placeholder {
    margin-top: $header-margin-top;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: $header-margin-top;
}

.inputContainer {
    width: min(95vw, 700px);
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonContainer button {
    width: min(25vw, 200px);
    height: 40px;
    font-size: min(3.5vw, 20px);
    background-color: inherit;
    border-color: $light-gray;
    color: $dark-gray;
}

.buttonContainer button:hover {
    background-color: $light-gray !important;
    color: $dark-gray !important;
}

.tableWrapper {
    width: 100%;
    margin: auto;
    margin-top: min(10vw, 70px);
    overflow: hidden;
    overflow-x: scroll;
}

.tableWrapper::-webkit-scrollbar {
    display: none;
}

.tableWrapper table {
    width: 100%;
    margin: auto;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
}

@media only screen and (max-width: 1200px) {
    .tableWrapper table {
        width: 1000px;
    }
}

.tableWrapper th {
    text-align: left;
    border: none;
    border-bottom: 1px solid gray;
}

.tableWrapper td {
    padding-top: min(5vw, 30px);
    padding-bottom: min(5vw, 30px);
    border: none;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    overflow: hidden;
}

.tableWrapper tr {
    margin-bottom: 100px !important;
    cursor: pointer;
}

.tableWrapper tr:hover {
    background-color: #efefef;
}
