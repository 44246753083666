@import '../../../../variables.module.scss';

.container {
    max-width: 1000px;
    margin: auto;
    margin-bottom: $large-margin;
}

.container h1 {
    text-align: center;
}

.quoteSection {
    margin-top: $large-margin;
}

.quoteSection h1 {
    text-align: center;
}
