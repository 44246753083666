@import '../../variables.module.scss';

.container {
    width: calc(40% - $body-margin - $body-margin);
    margin: $body-margin;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container input {
    width: min(5vw, 20px);
    height: min(5vw, 20px);
}
