@import '../../../variables.module.scss';

.placeholder {
    margin-top: $header-margin-top;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: $header-margin-top;
}

.inputContainer {
    width: min(95vw, 700px);
}

.buttonContainer {
    width: min(95vw, 700px);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttonContainer button {
    height: 40px;
    font-size: min(4vw, 20px);
    background-color: inherit;
    border-color: $light-gray;
    color: $dark-gray;
}

.buttonContainer button:hover {
    background-color: $light-gray !important;
    color: $dark-gray !important;
}
