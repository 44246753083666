@import '../../../../variables.module.scss';

.componentWrapper {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1s;
    border: 1px solid #a5ce8a;
    margin-bottom: min(15vw, 100px);
}

.contractingComponentWrapper {
    border: 1px solid #e79124;
}

.sectionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: min(4vw, 30px);
    border-bottom: 1px solid #dddddd;
}

.datePicker {
    justify-content: center;
}

.dayWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.dayRows {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5vw;
}

.dayRows h5 {
    font-weight: normal;
    margin: 0;
    width: min(40vw, 200px);
    font-size: $commercial-font;
}

.dayRows input {
    height: min(5vw, 20px);
    width: min(5vw, 20px);
}

.componentWrapper input[type='text'] {
    width: min(60vw, 280px);
    height: min(12vw, 30px);
    font-size: $commercial-font;
}

.componentWrapper input[type='datetime-local'] {
    width: min(60vw, 290px);
    height: min(11vw, 30px);
    font-size: $commercial-font;
}

.componentWrapper select {
    width: min(64vw, 310px);
    height: min(12vw, 50px);
    font-size: $commercial-font;
}

@media only screen and (max-width: 500px) {
    .componentWrapper input[type='text'] {
        width: 60vw;
        height: 12vw;
    }

    .componentWrapper input[type='datetime-local'] {
        width: 60vw;
        height: 11vw;
    }

    .componentWrapper select {
        width: 64vw;
        height: 12vw;
    }
}

.componentWrapper select {
    font-size: $commercial-font;
    outline: none;
}

.componentWrapper input {
    text-align: center;
    outline: none;
    padding: 0.5vw;
}

.janitorialButton button {
    width: min(65vw, 300px);
    margin-top: $large-margin;
    border: 1px solid #a5ce8a;
    background-color: #a5ce8a;
}

.janitorialButton button:hover {
    color: #a5ce8a !important;
}

.contractingButton button {
    width: min(65vw, 300px);
    margin-top: $large-margin;
    border: 1px solid #e79124;
    background-color: #e79124;
}

.contractingButton button:hover {
    color: #e79124 !important;
}

.adjusterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adjusterWrapper img {
    cursor: pointer;
    width: min(4vw, 20px);
    height: min(4vw, 20px);
}

.adjusterWrapper input {
    width: min(15vw, 50px);
    margin-right: min(5vw, 50px);
    margin-left: min(5vw, 50px);
    font-size: $commercial-font;
    font-weight: bold;
}

.rushServiceWarningText {
    margin-top: min(5vw, 20px);
    padding: 0.5vw;
    background-color: #ffeb3b;
    color: black;
    text-align: center;
}

/* Loader */

.loader {
    margin: auto;
    border: 0.5vw solid white;
    border-radius: 50%;
    border-top: 0.5vw solid rgb(0, 199, 237);
    width: min(3vw, 25px);
    height: min(3vw, 25px);
    overflow: hidden;

    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
