.scheduleContainer {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #f5f5f7;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.serviceContainer {
    width: min(90vw, 400px);
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: min(2vw, 15px);
    margin: 0vw 1vw 0vw 1vw;
    margin-bottom: min(15vw, 100px);

    border:
        1px solid 242,
        242,
        242;
    border-radius: min(1vw, 15px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(242, 242, 242, 0.3);
    backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
}

.serviceContainer textarea {
    width: 95%;
    margin-top: min(5vw, 30px);
    margin-bottom: min(5vw, 30px);
    height: 100%;
    font-size: min(5vw, 16px);
    outline: none;
    background-color: white;
    opacity: 1;
}

.serviceContainer p {
    font-size: min(5vw, 16px);
    color: black;
}

.serviceContainerInner {
    width: 100%;
}

.serviceContainerInner a {
    color: inherit;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
}