@import '../../../../variables.module.scss';

.centerAlign {
    text-align: center;
    margin-top: min(20vw, 100px);
}

/* Search Container */

.searchContainer {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchContainer input {
    width: min(50vw, 500px);
    height: min(8vw, 40px);
    padding-left: min(3vw, 20px);
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
}

.searchContainer button {
    width: min(25vw, 200px);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 1vh;
    padding: 1vh;
    margin-top: min(5vw, 40px);
    border: 2px solid white;
}

/* Table */

.tableWrapper {
    width: 100%;
    margin: auto;
    margin-top: min(10vw, 70px);
    overflow: hidden;
    overflow-x: scroll;
}

.tableWrapper::-webkit-scrollbar {
    display: none;
}

.tableWrapper table {
    width: 100%;
    margin: auto;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
}

@media only screen and (max-width: 1200px) {
    .tableWrapper table {
        width: 1000px;
    }
}

.tableWrapper th {
    text-align: left;
    border: none;
    border-bottom: 1px solid gray;
}

.tableWrapper td {
    border: none;
    border-bottom: 1px solid gray;
    white-space: nowrap;
    overflow: hidden;
}

.tableWrapper tr {
    margin-bottom: 100px !important; /* Adjust margin as needed */
}

.tableWrapper tr:hover {
    background-color: #efefef;
}
