@import '../../variables.module.scss';

.container {
    width: calc(30% - $body-margin - $body-margin);
    margin: $body-margin;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container select {
    width: min(60vw, 320px);
    height: min(9vw, 45px);
    padding-left: min(3vw, 20px);
    outline: none;
    border: 1px solid $dark-gray;
    border-radius: 5px;
}
