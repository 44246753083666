@import '../../variables.module.scss';

.container {
    width: calc(100% - $body-margin - $body-margin);
    margin: $body-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container textarea {
    width: 95%;
    height: min(50vw, 200px);
    padding-left: min(3vw, 20px);
    outline: none;
    border: 1px solid $dark-gray;
    border-radius: 5px;
}
