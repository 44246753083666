body {
    background-color: white;
    margin: min(3vw, 15px);
    font-family: Oxygen, sans-serif;
}

body p {
    font-size: min(4vw, 16px);
    line-height: 1.8;
}
