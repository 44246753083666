@import '../../../variables.module.scss';

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: black;
}

.stories {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stories video {
    width: 100%;
}

.overlayBox {
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 1;
}

.profileBar {
    position: absolute;
    width: 96%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    top: min(10vw, 30px);
    z-index: 2;
}

.profileBar img {
    width: min(20vw, 100px);
}

.postDetails {
    position: absolute;
    width: 96%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: min(10vw, 30px);
}

.postDetails p {
    color: $white;
    margin-left: 1vw;
}

.progressBarContainer {
    position: fixed;
    top: min(3vw, 15px);
    width: 96%;
    height: 10px;
    display: flex;
    align-items: center;
}

.progressBar {
    height: 2px;
    background-color: $dark-gray;
    margin-right: 0.5vw;
}

.progressBarFill {
    position: relative;
    width: 0%;
    height: 2px;
    background-color: $white;
}
