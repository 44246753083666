@import '../../variables.module.scss';

.container {
    margin-top: $header-margin-top;
    margin-bottom: $header-margin-top;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container img {
    width: $header-logo-width;
}
