.quoteSection {
    width: 90%;
    margin: auto;
    margin-bottom: min(5vw, 40px);
    border: 1px solid #a5ce8a;
    border-radius: 10px;
    height: min(15vw, 150px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    color: gray;
}

.quoteSection h2 {
    font-size: min(4.5vw, 25px);
}

.contractingSection {
    border: 1px solid #e79124;
}

.contractingSection:hover {
    background-color: #e79124 !important;
    color: white !important;
    transition: background-color 1s ease;
}

.quoteSection:hover {
    background-color: #a5ce8a;
    color: white;
    transition: background-color 1s ease;
}

.lastSection {
    margin-bottom: 10vw !important;
}

.companyLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyLogo img {
    width: min(50vw, 250px);
}
