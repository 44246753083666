@import '../../variables.module.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: $service-card-width;
    height: $service-card-height;
    margin-right: $stories-right-margin;
    margin-left: $service-card-margin;
    border: 2px solid $green;
    border-radius: 5px;
    cursor: pointer;
}

.container img {
    width: $icon-width !important;
}

.contractingBorder {
    border: 2px solid $orange;
}

.quoteCheck {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
}

.quoteCheck p {
    font-size: min(3vw, 12px);
}

.quoteCheck img {
    width: min(6vw, 25px) !important;
    margin-left: $body-margin;
}
